/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/function-component-definition */
import { gql, useLazyQuery, useMutation } from "@apollo/client";
// import { MenuItem, TextField } from "@mui/material"
import React, { useContext, useEffect, useState } from "react";
import tw, { css, styled } from "twin.macro"; // eslint-disable-line no-unused-vars
import { AlarmOnRounded } from "@mui/icons-material";
import { number, shape, string } from "prop-types";
import { differenceInCalendarDays } from "date-fns";
import { pickBy } from "lodash";
import { Tooltip } from "@mui/material";

import {
  ContentWithUtilsBar,
  ErrorDialog,
  JimButton,
  LoadingDialog,
  LoadingTile,
  TilePrim,
} from "../../components/util-components";
import {
  drawerWidthSm,
  mq,
  timeFilterObjPastDayjs,
} from "../../utils/global-consts";
import { PageTitleContext } from "../../utils/pagetitle-provider";
import withRoles from "../../utils/with-roles";
import { UserRolesContext } from "../../utils/userroles-provider";
import JobFinishDialog from "../../components/JobFinishDialog";
import JimHelmet from "../../components/JimHelmet";
import UtilsBar from "../../layout/UtilsBar";
import JobTileUser from "../../components/JobTileUser";

const OLD_JOBINSTANCES = gql`
  query ListUserJobInstances(
    $worker: [ID]
    $startsAfter: Float
    $endsBefore: Float
  ) {
    listJobInstances(
      worker: $worker
      startsAfter: $startsAfter
      endsBefore: $endsBefore
    ) {
      id
      status
      job {
        title
      }
      description
      department {
        name
        facility {
          name
        }
      }
      start_time
      end_time
      actual_start_time
      actual_end_time
      length
      pay
    }
  }
`;

const FINISH_JOBINSTANCE = gql`
  mutation FinishJobInstance($id: ID!, $startTime: Float, $endTime: Float) {
    finishJobInstance(id: $id, startTime: $startTime, endTime: $endTime) {
      id
      actual_start_time
      actual_end_time
    }
  }
`;

const JobFinishArea = ({ data, timesSel }) => {
  const { userID } = useContext(UserRolesContext);
  const [showFinishDialog, setShowFinishDialog] = useState(false);
  const [finishJobInstance, { loading, error }] =
    useMutation(FINISH_JOBINSTANCE);

  const handleClickOnFinish = e => {
    e.stopPropagation();
    setShowFinishDialog(true);
  };

  const handleConfirm = (e, actualTimes) => {
    e.preventDefault();
    finishJobInstance({
      variables: {
        id: data.id,
        startTime: actualTimes.start.valueOf(),
        endTime: actualTimes.end.valueOf(),
      },
      refetchQueries: [
        {
          query: OLD_JOBINSTANCES,
          variables: {
            startsAfter: timesSel.left.valueOf(),
            endsBefore: timesSel.right.valueOf(),
            worker: [userID],
          },
        },
      ],
    }).then(() => setShowFinishDialog(false));
  };

  return (
    !data.actual_start_time && (
      <>
        <Tooltip title="Job beenden">
          <JimButton
            variant="cancel"
            onClick={handleClickOnFinish}
            tw="text-sm sm:text-base mt-1"
          >
            <AlarmOnRounded />
          </JimButton>
        </Tooltip>
        <JobFinishDialog
          open={showFinishDialog}
          handleClose={() => setShowFinishDialog(false)}
          jobInst={data}
          handleConfirm={handleConfirm}
        />
        <LoadingDialog open={loading} />
        {error && <ErrorDialog />}
      </>
    )
  );
};
JobFinishArea.propTypes = {
  data: shape({
    start_time: number,
    end_time: number,
    actual_start_time: number,
    actual_end_time: number,
    length: number,
    job: shape({
      title: string,
    }),
  }).isRequired,
  timesSel: shape().isRequired,
};

const SumDivWrapper = styled.div(() => [
  tw`w-full mx-auto fixed bottom-0 pr-4 mr-60 lg:px-0`,
  `${mq.sm} {
    width: calc(100% - ${drawerWidthSm}px);
  }`,
  tw`sm:max-w-4xl xl:max-w-5xl`,
]);

const JobsUserPast = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Abgeschlossene Einsätze"), []);
  const { userID } = useContext(UserRolesContext);
  // const [timeSelect, setTimeSelect] = useState("thisMonth")
  const [timesSel, setTimesSel] = useState(timeFilterObjPastDayjs);
  const [listJobInstances, { data: jobInstData, loading: jobInstLoading }] =
    useLazyQuery(OLD_JOBINSTANCES);
  const [isRed, setIsRed] = useState(false);

  useEffect(() => {
    listJobInstances({
      variables: pickBy({
        worker: [userID],
        startsAfter: timesSel.left.valueOf(),
        endsBefore: timesSel.right.valueOf(),
      }),
    });
  }, [timesSel]);

  useEffect(() => {
    if (jobInstData) {
      let res = false;
      for (let i = 0; i < jobInstData.listJobInstances.length; i += 1) {
        if (
          !jobInstData.listJobInstances[i].actual_start_time ||
          !jobInstData.listJobInstances[i].actual_end_time
        ) {
          res = true;
          break;
        }
      }
      setIsRed(res);
    }
  }, [jobInstData]);

  const getSumDiv = durationArr => {
    let sum = 0;
    durationArr.forEach(dur => {
      sum += dur;
    });
    return `${Math.floor(sum / 3600000)}h ${`0${Math.round(
      (sum - Math.floor(sum / 3600000) * 3600000) / 60000
    )}`.slice(-2)}m`;
  };

  return (
    <>
      <JimHelmet path="jobs/past" />
      <UtilsBar showTime past setTimesSel={setTimesSel} />
      <ContentWithUtilsBar>
        {jobInstData &&
          jobInstData.listJobInstances &&
          jobInstData.listJobInstances[0] && (
            <>
              {/* <div tw="flex">
                <div tw="flex-grow" />
                <div tw="w-20 sm:w-32">Dauer geplant</div>
                <div tw="w-20 sm:w-32">Dauer tatsächlich</div>
              </div> */}
              {/* <div tw="overflow-y-auto"> */}
              {jobInstData.listJobInstances.map((jobInst, ind) => (
                <>
                  <JobTileUser
                    jobInst={jobInst}
                    withDate={
                      ind === 0 ||
                      (ind > 0 &&
                        differenceInCalendarDays(
                          new Date(jobInst.start_time),
                          new Date(
                            jobInstData.listJobInstances[ind - 1].start_time
                          )
                        ) > 0)
                    }
                  >
                    <JobFinishArea data={jobInst} timesSel={timesSel} />
                  </JobTileUser>
                  {/* <JobTile
                    data={jobInst}
                    withDate={
                      ind === 0 ||
                      (ind > 0 &&
                        differenceInCalendarDays(
                          new Date(jobInst.start_time),
                          new Date(
                            jobInstData.listJobInstances[ind - 1].start_time
                          )
                        ) > 0)
                    }
                    timesSel={timesSel}
                  /> */}
                </>
              ))}
              {/* </div> */}
              <div tw="h-16" />
              <SumDivWrapper>
                <div tw="flex flex-grow rounded-t-md shadow-upper-md bg-white p-2">
                  <div tw="flex justify-end items-center p-2 flex-grow italic">
                    {`${timesSel.left.format(
                      "DD.MM."
                    )} bis ${timesSel.right.format("DD.MM.")}:`}
                  </div>
                  <div tw="flex flex-col">
                    <div tw="flex items-center justify-center text-base px-1 font-semibold">
                      {`geplant: ${getSumDiv(
                        jobInstData.listJobInstances.map(
                          jobInst => jobInst.end_time - jobInst.start_time
                        )
                      )}`}
                    </div>
                    <div
                      css={[
                        tw`flex items-center justify-center text-base px-1 font-semibold text-green-600`,
                        isRed && tw`text-red-500`,
                      ]}
                    >
                      {`${isRed ? "(" : ""}tatsächlich: ${getSumDiv(
                        jobInstData.listJobInstances.map(jobInst =>
                          jobInst.actual_start_time && jobInst.actual_end_time
                            ? jobInst.actual_end_time -
                              jobInst.actual_start_time
                            : jobInst.end_time - jobInst.start_time
                        )
                      )}${isRed ? ")" : ""}`}
                    </div>
                  </div>
                </div>
              </SumDivWrapper>
            </>
          )}
        {!jobInstLoading && jobInstData && !jobInstData.listJobInstances[0] && (
          <TilePrim>
            <div>
              Im gewählten Zeitraum wurden noch keine Einsätze abgeschlossen.
            </div>
          </TilePrim>
        )}
        {jobInstLoading && <LoadingTile />}
      </ContentWithUtilsBar>
    </>
  );
};

export default withRoles(JobsUserPast, "WORKER");
